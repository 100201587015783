import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { m, useScroll } from "framer-motion";

const ArchBackground = ({ children, color }) => {
  const ref = useRef(null);
  const [progress, setProgress] = useState(0);
  const [entered, setEntered] = useState();
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end 0.5"],
  });

  useEffect(() => {
    setProgress(scrollYProgress.get());
    const unsubscribe = scrollYProgress.onChange(v => {
      setProgress(v);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div
      className={classNames("relative z-30", {
        "pointer-events-none": !entered,
      })}
    >
      <m.div
        className={classNames(
          "texture-pattern pointer-events-none absolute top-px z-0 w-full origin-bottom -translate-y-full rounded-t-full pb-[50%]",
          "overflow-hidden",
          {
            "bg-viridian text-white": color === "viridian",
            "bg-black text-white": color === "black",
            "bg-coral text-black": color === "coral",
            "bg-green-light text-black": color === "green-light",
          }
        )}
        style={{ clipPath: `circle(${63.25 * progress}% at 50% 100%)` }}
      />
      <div ref={ref} className="pointer-events-none absolute top-14 h-[25vh]" />
      <m.div
        onViewportEnter={() => setEntered(true)}
        onViewportLeave={() => setEntered(false)}
        className={classNames("texture-pattern relative z-10", {
          "bg-viridian text-white": color === "viridian",
          "bg-black text-white": color === "black",
          "bg-coral text-black": color === "coral",
          "bg-green-light text-black": color === "green-light",
        })}
        style={{
          clipPath: `inset(0% ${50 - 50 * progress}% 0% ${
            50 - 50 * progress
          }%)`,
        }}
      >
        {children}
      </m.div>
    </div>
  );
};

ArchBackground.defaultProps = {
  color: "viridian",
};

export default ArchBackground;
